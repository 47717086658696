import { Typography } from "@mui/material";
import { CartContext } from "../components/Cart/CartContext";
import React, { useState } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";
import queryString from "query-string";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  getProductData,
  productsArray,
} from "../components/Products/ProductsArray";

const stripePromise = loadStripe(
  "pk_live_51NtH9NKNMdnS9NXCRQRpQMrRBqn5X8S6uLye6IUAMP9Bei6WWJ4knbZhL5reXSVYP2zdhCdpLrUtCxDhFv9UzViP00UP76hqnV"
);

const Success = () => {
  const location = useLocation();
  const { search } = location;
  const { session_id } = queryString.parse(search);
  const [stripeLineData, setStripeLineData] = React.useState([]);
  const [stripeData, setStripeData] = React.useState(null);
  const [dog, setDog] = React.useState(session_id);
  // Ground SHIPPING CODE
  const cart = useContext(CartContext);
  const [payment, setPayment] = React.useState(null);
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    // Your code that depends on the updated stripeLineData state
    cart.clearCart();
    console.log(stripeLineData); // This may not reflect the updated state
    console.log(payment)
  
    // Add a dependency array to monitor changes to stripeLineData
  }, [stripeLineData, payment]);
  
  const getCustomerLineItems = async () => {
    const combinedArray = [];
    try {
      const stripe = stripePromise;
      const response = await fetch(
        `https://api.stripe.com/v1/checkout/sessions/${dog}/line_items`,
        {
          headers: {
            Authorization: `Bearer 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`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log(data.data, "stripeLineData");
      console.log(data?.data.map((item) => item.price.id));
      const lineItemIDs = data?.data.map((item) => item.price.id);
      // console.log(lineItemIDs);
      // setStripeLineData(lineItemIDs)
      productsArray.forEach((pop) => {
        pop.products.forEach((product) => {
        if (lineItemIDs.includes(product.id)) {
          console.log(`Match found for product: ${product.name}`);
          let foo = getProductData(product.id);
          // console.log([foo], "foo");
          combinedArray.push([...foo]);
          console.log(combinedArray, 'combinedArray')
        }
      })});
      // Mapping out the IDs inside the price object for each item
      //
      // const x = getProductData(data?.data.map(item => item.price.id))
      // console.log(x)
      setStripeLineData(combinedArray);
      console.log(stripeLineData, "Real Data")
      return data.data;
    } catch (error) {
      console.error("Error in getCustomerLineItems:", error);
      // Handle the error or throw it further
    }
  };

  const getCustomerData = async () => {
    try {
      const stripe = stripePromise;
      const response = await fetch(
        `https://api.stripe.com/v1/checkout/sessions?${dog}/line_items`,
        {
          headers: {
            Authorization: `Bearer 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`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log(data.data[0], "Stripe Customer Data");
      console.log(data);
      setStripeData(data.data[0]); //
      // console.log(data.data[0].payment_method_configuration_details.id)
   
  
        return data.data;
      } catch (error) {
        console.error("Error in getCustomerLineItems:", error);
        // Handle the error or throw it further
      }
    };



    // const fetchPaymentDetails = async () => {
    //   try {
    //     const stripe = stripePromise;
    //     const response = await fetch(
    //       `https://api.stripe.com/v1/checkout/sessions?${dog}/line_items`,
    //       {
    //         headers: {
    //           Authorization: `Bearer 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`,
    //         },
    //       }
    //     );
    
    //     if (!response.ok) {
    //       throw new Error("Failed to fetch data");
    //     }
    //     const data = await response.json();
    //     console.log(data.data[0], "Stripe Customer Data");
    //     console.log(data);
    //     setStripeData(data.data[0]);
    //     const testPayment = data.data[0].payment_method_configuration_details.id
    //       const response1 = await fetch(
    //         `https://api.stripe.com/v1/payment_method_configurations/${testPayment}`,
    //         {
    //           headers: {
    //             Authorization: `Bearer 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`,
    //           },
    //         }
    //       );
    //       if (!response1.ok) {
    //         throw new Error("Failed to fetch data");
    //       }
    
    //       const data1 = await response1.json(); // Fixed: Changed response to response1
    //       setPayment(data1);
    //   } catch (error) {
    //     console.error("Error in getCustomerLineItems:", error);
    //     // Handle the error or throw it further
    //   }
    // };
    

  React.useEffect(() => {

    const fetchData = async () => {
      await getCustomerLineItems();
      await getCustomerData();
      // await fetchPaymentDetails();
    };

    console.log(stripeLineData);
    console.log(payment)
    fetchData();
    const storedChoice1 = JSON.parse(localStorage.getItem("selectedChoices1"));
    console.log(storedChoice1);
    if (storedChoice1?.length > 0) {
      const secondEmailMessage = `
      A client just purchased SeaMoss Bundle Collection and wants ${storedChoice1} gel(s).
     `;
      // Send the additional email
      const additionalEmailParams = {
        from_email: "seamossforms@gmail.com",
        to_email: "seamossforms@gmail.com",
        user_email: "seamossforms@gmail.com",
        from_name: "SeaMos-Sista LLC.",
        message: secondEmailMessage,
        to_name: "SeaMos-Sista LLC.",
      };
      emailjs
        .send(
          "service_tx2q19a",
          "template_bv1xoig",
          additionalEmailParams,
          "AZPbts4c28Rg1UP4C",
          {
            publicKey: "AZPbts4c28Rg1UP4C",
            to_name: "Danielle",
            from_name: "SeaMos-Sista LLC.",
            message: additionalEmailParams,
          }
        )
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          localStorage.clear();
        })
        .catch((error) => {
          console.log("FAILED...", error);
        });
    }
  }, []);

 
  return (
    <section class="py-40 bg-white ">
      <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="max-w-6xl mx-auto">
          <div class="flex items-start lg:items-center">
            <svg
              class="flex-shrink-0 text-green-500 w-11 h-11"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            <div class="ml-4">
              <h1 class="text-2xl  font-bold text-gray-900 sm:text-3xl">
                We received your order!
              </h1>
              <p class="mt-2 text-sm font-normal text-gray-600">
              Your order is completed and ready to be processed.
              </p>
            </div>
          </div>

          <div class="grid grid-cols-1 mt-8 lg:grid-cols-5 lg:items-start xl:grid-cols-6 gap-y-10 lg:gap-x-12 xl:gap-x-16">
            <div class="pt-6 border-t border-gray-200 lg:col-span-3 xl:col-span-4">
              <h2 class="text-sm font-bold text-gray-500">Order Details</h2>

              <div class="flow-root mt-8">
                <ul class="divide-y divide-gray-200 -my-7">
                  {stripeLineData.map(item => (
                  <li class="flex items-stretch py-7">
                    <div class="flex-shrink-0">
                      <img
                        class="object-cover rounded-lg w-28 h-28"
                        src={item[0].image}
                        alt=""
                      />
                    </div>

                    <div class="flex flex-col justify-between ml-5">
                      <div class="flex-1">
                        <p class="text-base font-bold text-gray-900">
                          {item[0].name}
                        </p>
                        <p class="mt-1.5 text-sm font-medium text-gray-500">
                          {item[0].size[0]}
                        </p>
                      </div>

                      <p class="mt-auto text-sm font-bold text-gray-900">
                      ${item[0].price[0].toFixed(2)}
                      </p>
                    </div>
                  </li>))}
                </ul>
              </div>
            </div>

            <div class="lg:col-span-2 lg:sticky lg:top-6">
              <div class="overflow-hidden rounded bg-gray-50">
                <div class="px-4 py-6 sm:p-6 lg:p-8">
                  <div class="space-y-9">
                    <div>
                      <h3 class="text-sm font-bold text-gray-900">
                        Shipping Address
                      </h3>
                      <p class="mt-4 text-sm font-medium text-gray-600">
                        {stripeData?.customer_details.name}
                      </p>
                      <p class="mt-3 text-sm font-medium text-gray-600">
                        {stripeData?.customer_details.address.line1}.{" "}
                        {stripeData?.customer_details.address.city},{" "}
                        {stripeData?.customer_details.address.state}{" "}
                        {stripeData?.customer_details.address.postal_code}, USA
                      </p>
                      <hr class="mt-6 border-gray-200" />

<div class="mt-6 space-y-4">
  <li class="flex items-center justify-between">
    <p class="text-sm font-medium text-gray-600">Subtotal</p>
    <p class="text-sm font-medium text-gray-600">${(stripeData?.amount_subtotal / 100).toFixed(2)}</p>
  </li>

  <li class="flex items-center justify-between">
    <p class="text-sm font-medium text-gray-600">Shipping</p>
    <p class="text-sm font-medium text-gray-600">${(stripeData?.shipping_options[0].shipping_amount / 100).toFixed(2)}</p>
  </li>

  <li class="flex items-center justify-between">
    <p class="text-sm font-bold text-gray-900">Total</p>
    <p class="text-sm font-bold text-gray-900">${(stripeData?.amount_total / 100).toFixed(2)}</p>
  </li>
</div>
                    </div>
 
                    {/* <div>
                      <h3 class="text-sm font-bold text-gray-900">
                        Billing Address
                      </h3>
                      <p class="mt-4 text-sm font-medium text-gray-600">
                        Wilson Baker
                      </p>
                      <p class="mt-3 text-sm font-medium text-gray-600">
                        4517 Washington Ave. Manchester, Kentucky 39495, USA
                      </p>
                    </div> */}

                    {/* <div>
                      <h3 class="text-sm font-bold text-gray-900">
                        Payment Info
                      </h3>
                      <p class="mt-4 text-sm font-medium text-gray-600">
                        Credit Card
                      </p>
                      <p class="mt-1 text-sm font-medium text-gray-600">
                        VISA
                        <br />
                        **** 4660
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Success;
