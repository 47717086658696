
import { CartContext } from "./CartContext";
import { useContext } from "react";
import { getProductData1 } from "../Products/ProductsArray";

import React from "react";

const NewCart = (props) => {
  // React.useEffect(() => {
  //   cart.clearCart();
  //   localStorage.clear();
  //   })
  const cart = useContext(CartContext);
  const id = props.id;
  console.log(id);
  const quantity = props.quantity;
  let dog = getProductData1(id);
  console.log(dog);
  console.log(id, quantity);
  console.log(cart.items);
  
  let filteredArray = dog.filter((_, index) => index !== 1);
  const trueValues = JSON.parse(localStorage.getItem("selectedChoices1")) || [];
  // Filter out arrays with length less than 2
  const filteredValues = trueValues.filter((arr) => arr.length >= 2);
  // Update the 'selectedChoices1' in localStorage with filtered values
 const money = localStorage.setItem("selectedChoices1", JSON.stringify(filteredValues));
  console.log(filteredValues);
const getOrderLabel = (index, totalItems) => {
  const labels = ["st", "nd", "rd"];
  const labelIndex = (index + 90) % 10 < 3 ? (index + 90) % 10 : 0;
 if (labelIndex >= 4) {
    return "th"; // Display "th" for 4th item and beyond

  } else if(labelIndex === 0) {
    return "st"
  }else {
    return labels[labelIndex]; // Display appropriate label for 1st, 2nd, and 3rd items
  }
};

  
  // This makes sure once everything is cleared from cart, nothing shows
  for (let i = 0; i < filteredArray.length; i++) {
    if (filteredArray[i] === null) {
      return null;
    }
  }

  // console.log(filteredArray)
  if (dog[5] === false) {
    console.log("false");
  } else {
    console.log("true");
    console.log(filteredArray);
  }

  return (
    <>
      <div class="flow-root mt-7">
        <ul class="divide-y divide-gray-200 -my-7">
          <li class="flex py-7">
            <div class="flex-shrink-0">
              <img
                class="object-cover w-16 h-16 rounded-lg"
                src={filteredArray[2]}
                alt=""
              />
            </div>

            <div class="flex-1 ml-5">
              <div class="relative sm:grid sm:grid-cols-2 sm:gap-x-5 sm:pr-0">
                <div class="pr-9 sm:pr-5">
                  <p class="text-base font-bold text-gray-900">
                    {filteredArray[0]}
                  </p>
                  <p class="mt-1.5 text-sm font-medium text-gray-500">
                    Size: {filteredArray[3]}
                  </p>
                  <p class="mt-1.5 text-sm font-medium text-gray-500">
                    {" "}
                    {filteredArray[0] === "SeaMoss Bundle Collection" ? (
  <ul >
    {filteredValues.map((value, index) => (
      <li className="py-2" key={index}>
        <span>{`${index + 1}${getOrderLabel(index)} Order: `}</span>
        <br/>
        {value.map((item, i) => (
          <span key={i}>
            {item} Sea Moss Gel
            <br/>
            {i !== value.length - 1 && <> </>}
          </span>
        ))} {/* Add closing parenthesis here */}
      </li>
    ))}
  </ul>
) : null}

                  </p>
                </div>

                <div class="flex items-end justify-between mt-3 sm:justify-end sm:pr-14 sm:items-start sm:mt-0">
                  <p class="flex-shrink-0 w-20 text-base font-bold text-left text-gray-900 sm:text-right sm:order-2 sm:ml-8">
                    {" "}
                    ${(quantity * filteredArray[1]).toFixed(2)}
                  </p>

                  <div class="sm:order-1 border-1">
                    <div className="flex items-center mx-auto ">
                      <button
                        onClick={() => {
                          cart.removeOneFromCart(id);
                          const updatedValues = [...filteredValues];
                          updatedValues.pop();
                          localStorage.setItem("selectedChoices1", JSON.stringify(updatedValues));
                        }}
                        className="px-2"
                      >
                        <i className="fas fa-minus"></i>
                      </button>

                      <div className="px-4">{quantity}</div>

                      <button
                        onClick={() => cart.addOneToCart(id)}
                        className="px-2"
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>

                  <div class="absolute top-0 right-0 flex">
                    <button
                      onClick={() => {localStorage.clear();cart.deleteFromCart(id)}}
                      type="button"
                      class="inline-flex p-2 -m-2 text-gray-400 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:text-gray-900"
                    >
                      <svg
                        class="w-5 h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <hr class="border-gray-200 mt-7" />
    </>
  );
};

export default NewCart;
