import { createContext, useState, useEffect } from "react";
import { productsArrayWithId, getProductData1 } from "../Products/ProductsArray";



export const CartContext = createContext({
    items: [],
    getProductQuantity: () => {},
    addOneToCart: () => {},
    removeOneFromCart: () => {},
    deleteFromCart: () => {},
    getTotalCost: () => {},
    clearCart: () => {},
});



export function CartProvider({children}) {
   const [ cartProducts, setCartProducts] = useState([]);
   useEffect(() => {
    const storedCartData = localStorage.getItem('cart');
    if (storedCartData) {
      setCartProducts(JSON.parse(storedCartData));
    }
  }, []);

  
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartProducts));
  }, [cartProducts]);
//    only grabbing the id and the quantity
function getProductQuantity(id) {
   const quantity = cartProducts.find(product => product.id === id)?.quantity;
   if ( quantity === undefined ) {
    return 0;
}
return quantity;
}


function clearCart() {
    setCartProducts([]);
  }

function addOneToCart(id, description) {
    console.log(cartProducts);
    const quantity = getProductQuantity(id);
    if (quantity === 0 ) {  //product in cart
        setCartProducts(
            [
                ...cartProducts,
                {
                    id: id,
                    quantity: 1,
                    description: description
                }])
    } else { //product is not in cart
        setCartProducts(
            cartProducts.map(
                product => 
                product.id === id                               //if condition
                ? {...product, quantity: product.quantity + 1 } // id statement is true
                : product                                      // if statement is false
            )
        )
    }
}


function removeOneFromCart(id) {
    const quantity = getProductQuantity(id);

    if (quantity === 1) {
        deleteFromCart(id);
} else {
    setCartProducts(
        cartProducts.map(
            product => 
            product.id === id                               //if condition
            ? {...product, quantity: product.quantity - 1 } // id statement is true
            : product                                      // if statement is false
        )
    )
}
}

function deleteFromCart(id) {
    setCartProducts(
        cartProducts =>
        cartProducts.filter(currentProduct => {
                return currentProduct.id !== id
            })
    )
}



function getTotalCost() {
    let totalCost = 0;
  
    console.log(cartProducts);//+
    cartProducts.forEach((cartItem) => {//+
        console.log(cartItem.id);//+
        const productData = getProductData1(cartItem.id);

        console.log(productData);//+
        console.log(cartProducts);//+
        totalCost += (productData[2] * cartItem.quantity);

        console.log(totalCost);//+
    });//+
    return totalCost;
}


   const contextValue = {
        items: cartProducts,
    getProductQuantity,
    addOneToCart,
    removeOneFromCart,
    deleteFromCart,
    getTotalCost,
    clearCart,
    }


    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )


    }


    export default CartProvider;
// CODE DOWN HERE

// Context (cart, addToCart, removeFromCart)
// Provider -> gives your React app access to all the things in your context